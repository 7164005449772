@import '~antd/dist/antd.less';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@page { 
  size: auto;
  margin: 8mm 16mm 8mm 16mm;
}

.full-screen {
  width: 100vw;
  height: 100vh;
}

.full-wdith {
  width: 100%;
}

.scroll-container {
  overflow-y: hidden;
  overflow-x: hidden;
}
.scroll-body {
  overflow-y: auto;
  overflow-x: hidden;
}

.print-page {
  width: 210mm;
  min-height: 297mm;
  padding: 20mm;
  margin: 10mm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.long-link {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

}

.gray-background {
  background-color:#EEEEEE;
}

.freeze-scroll {
  overflow: hidden;
}
.freeze-scroll-x {
  overflow-x: hidden;
}
.self-scroll {
  overflow-y: auto;
}
.self-scroll-x {
  overflow-x: auto;
}

.line-through {
  text-decoration: line-through;
}

.max-two-lines {
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.selectable:hover { 
  cursor: pointer;
  background-color: #cfe9d9;
}

.hoverable {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.single-line {
  text-overflow: ellipsis; 
  overflow: hidden; 
  white-space: nowrap;
}

.html-editor {
  height: 560px;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-screen-height {
  height: 100vh;
}

.full-page {
  width: 100%;
  height: 100%;
}

.flex {
  display: -webkit-flex; /* Safari */
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column; /* Safari */
}
.flex-fill {
  -webkit-flex: 1; /* Safari */
  flex: 1;
}
.flex-row {
  flex-direction: row;
  -webkit-flex-direction: row; /* Safari */
}
.flex-column {
  flex-direction: column;
  -webkit-flex-direction: column; /* Safari */
}
.flex-center {
  -webkit-justify-content: center; /* Safari */
  justify-content: center;
}
.flex-wrap {
  -webkit-flex-wrap: wrap; /* Safari */
  flex-wrap: wrap;
}
.flex-space-between {
  -webkit-justify-content: space-between; /* Safari */
  justify-content: space-between;
}
.flex-space-around {
  -webkit-justify-content: space-around; /* Safari */
  justify-content: space-around;
}
.flex-align-center {
  align-items: center;
  -webkit-align-items: center; /* Safari */
}
.flex-align-start {
  align-items: flex-start;
  -webkit-align-items: flex-start; /* Safari */
}
.flex-align-end {
  align-items: flex-end;
  -webkit-align-items: flex-end; /* Safari */
}
.flex-align-stretch {
  align-items: stretch;
  -webkit-align-items: stretch; /* Safari */
}
.flex-end {
  justify-content: flex-end;
  -webkit-justify-content: flex-end; /* Safari */
}
.margin {
  margin: 16px;
}
.margin-half {
  margin: 8px;
}
.margin-top {
  margin-top: 16px;
}
.margin-top-half {
margin-top: 8px;
}
.margin-bottom {
  margin-bottom: 16px;
}
.margin-bottom-half {
margin-bottom: 8px;
}
.margin-horizontal {
  margin: 0px 16px;
}
.margin-horizontal-half {
margin: 0px 8px;
}
.margin-vertical {
  margin: 16px 0px;
}
.margin-right {
  margin-right: 16px;
}
.margin-left {
  margin-left: 16px;
}
.padding-half {
 padding: 8px;
}
.padding {
  padding: 16px;
}
.padding-top {
padding-top: 16px;
}
.padding-top-half {
  padding-top: 8px;
}
.padding-bottom {
  padding-bottom: 16px;
}
.padding-bottom-half {
padding-bottom: 8px;
}
.padding-left {
  padding-left: 16px;
}
.padding-left-half {
padding-left: 8px;
}
.padding-right {
  padding-right: 16px;
}
.padding-right-half {
padding-right: 8px;
}
.padding-horizontal {
  padding: 0px 16px;
}
.padding-horizontal-half {
  padding: 0px 8px;
}
.padding-vertical {
  padding: 16px 0px;
}
.padding-vertical-half {
padding: 8px 0px;
}
.font-clip {
  text-overflow: clip;
  overflow: hidden;
  white-space: nowrap;
}

.phone-frame {
  -ms-zoom: 0.75;
  zoom: 0.75;
  transform: scale(0.75);
  -moz-transform: scale(0.75);
  transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.75);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.75);
  -webkit-transform-origin: 0 0;
}
@primary-color: #006c49;@info-color: #0277BD;@processing-color: #0277BD;@success-color: #4CAF50;@error-color: #B3261E;@primary-1: #cfe9d9;@primary-2: #cfe9d9;@descriptions-bg: #dbe5dd;@component-background: #fbfdf8;@body-background: #fbfdf8;@menu-inline-submenu-bg: #fbfdf8;@layout-body-background: #fbfdf8;@table-expanded-row-bg: #fbfdf8;@layout-header-background: #fbfdf8;@layout-sider-background-light: #fbfdf8;@layout-trigger-background-light: #fbfdf8;@background-color-light: #dbe5dd;@background-color-base: #dbe5dd;@table-body-sort-bg: #dbe5dd;@table-row-hover-bg: #cfe9d9;@alert-info-bg-color: #c1e9fb;@alert-warning-bg-color: #c1e9fb;@alert-error-bg-color: #F9DEDC;@alert-success-bg-color: #cfe9d9;